.newsInfo-container {
    background-color: #f5f5f5;
    padding: px2rem(60px) 0 px2rem(80px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .newsInfo-content {
        box-sizing: border-box;
        background-color: #fff;
        width: 100%;
        .article {
            width: 100%;
            box-sizing: border-box;
            padding: px2rem(55px) px2rem(90px) px2rem(76px) px2rem(80px);
            @media screen and (max-width: 1400px) {
                padding: px2rem(60px) px2rem(30px) px2rem(80px);
            }
            .article-title {
                box-sizing: border-box;
                padding-bottom: px2rem(30px);
                border-bottom: 1px dashed #b2b2b2;
                @media screen and (max-width: 1400px) {
                    padding-bottom: px2rem(20px);
                }
                .article-title-name {
                    font-size: 30px;
                    line-height: 1;
                    font-weight: bold;
                    color: #555;
                    text-align: center;
                    @media screen and (max-width: 1000px) {
                        font-size: 20px;
                        line-height: 1.1;
                    }
                }
                .article-title-attr {
                    text-align: center;
                    margin-top: px2rem(24px);
                    ul {
                        display: flex;
                        justify-content: center;
                        @media screen and (max-width: 750px) {
                            flex-wrap: wrap;
                        }
                        li {
                            margin-right: 10px;
                            span {
                                font-size: 13px;
                                color: #666;
                                @media screen and (max-width: 750px) {
                                    font-size: 12px;
                                }
                            }
                        }
                        li:nth-last-of-type(1) {
                            margin-right: 0px;
                        }
                    }
                }
            }
            .article-content {
                color: #666;
                padding-top: px2rem(24px);
                line-height: 26px;
                @include articleReset(14px, 26px);
                @media screen and (max-width: 1000px) {
                    font-size: 13px;
                    @include articleReset(13px, 24px);
                }
                .img-mt20 {
                    margin-top: px2rem(20px);
                }
                .img-center {
                    text-align: center;
                }
            }
            .lxfs-content {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
            }
        }
    }
    .article-page {
        box-sizing: border-box;
        margin-top: px2rem(48px);
        display: flex;
        height: 55px;
        @media screen and (max-width: 500px) {
            height: 40px;
        }
        a {
            display: inline-block;
            flex: 1;
            background-color: #f15d0b;
            transition: all .3s;
        }
        a:hover {
            background-color: #e85403;
        }
        .article-page-same {
            text-align: center;
            line-height: 55px;
            font-size: 14px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(255, 255, 255, .1);
            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 40px;
            }
            i {
                color: #fff;
                font-size: 16px;
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                }
            }
            span {
                margin-left: px2rem(10px);
                @media screen and (max-width: 360px) {
                    margin-left: 0px;
                }
            }
        }
        .page-prev {
            position: relative;
            i {
                display: inline-block;
            }
        }
        .page-back {
            position: relative;
            span {
                margin-left: px2rem(10px);
            }
            i {
                font-size: 22px;
                @media screen and (max-width: 500px) {
                    font-size: 18px;
                }
            }
        }
        .page-next {
            i {
                display: inline-block;
            }
            span {
                margin-left: 0px;
                margin-right: px2rem(10px);
                @media screen and (max-width: 360px) {
                    margin-right: 0px;
                }
            }
        }
    }
}


