.inside-menu {
    position: relative;
    margin-top: -2px;
    z-index: 2;
    background-color: #fff;
    //box-shadow: 0 5px 40px -6px rgba(0, 0, 0, .2);
    .wp1300 {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .wp1360 {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .inside-swiper {
        overflow: hidden;
        .swiper-wrapper {
            font-size: 0;
        }
        .swiper-slide {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: auto;
            a {
                text-align: center;
                box-sizing: border-box;
                padding: px2rem(32px) px2rem(52px);
                font-size: px2rem(16px);
                position: relative;
                display: block;
                color: #444;
                transition: all .3s;
                @media screen and (max-width: 700px) {
                    padding: 12px 25px;
                    font-size: 14px;
                }
            }
            a:before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0px;
                height: 8px;
                width: 1px;
                background-color: #ddd;
                content: '';
            }

            &.on,
            &:hover {
                a {
                    color: $baseColor;
                    //font-weight: bold;
                }
            }
        }

        .swiper-slide:nth-last-of-type(1) {
            a:before {
                content: none;
            }
        }




    }
    .same {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        //border: 1px solid $onColor;
        //background-color: $onColor;
        text-align: center;
        color: #333;
        outline: none;
        transition: all .3s;
        cursor: pointer;
        box-sizing: border-box;
        @media screen and (max-width: 750px) {
            width: 20px;
            height: 20px;
            line-height: 20px;
        }
    }
    .same:hover {
        color: $baseColor;
    }
    .inside-next {
        right: -35px;
        @media screen and (max-width: 1400px) {
            right: 0;
        }
    }
    .inside-prev {
        left: -35px;
        transform: translateY(-50%) rotate(180deg);
        @media screen and (max-width: 1400px) {
            left: 0;
        }
    }
}


.header-tree-banner {
    overflow: hidden;
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        padding-top: px2rem(100px);
    }
    @media screen and (max-width: 500px) {
        padding-top: 60px;
    }
    .img-pc,
    .img-mobile {
        position: relative;
        padding-bottom: (450 / 1920) *100%;
        overflow: hidden;
        img {
            @include baseImg;
        }
    }
    .img-mobile {
        padding-bottom: (400 / 750) *100%;
    }
}
.banner-web {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}
.banner-mobile {
    display: none;
    @media screen and (max-width: 1000px) {
        display: block;
    }
}

.zztj {
    display: none;
}