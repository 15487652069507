.error-con {
    padding: px2rem(100px) 0;
    background: #fff;
}

.error-box {
    background-color: #fff;
    box-sizing: border-box;
    padding: px2rem(50px);
    text-align: center;
    width: 1360px;
    max-width: 100%;
    margin: 0 auto;

    img {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
    }

    .tips {
        margin: 20px auto;
        font-size: 18px;
        color: #666;
    }

    .btn {
        a {
            display: inline-block;
            vertical-align: top;
            width: 170px;
            height: 40px;
            margin: 0 2px;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            font-size: 16px;
            background: #999;
            transition: all .2s;
            line-height: 40px;
            max-width: 45%;

            &:hover {
                background: lighten(#999, 5%);
            }
        }
    }
}