.newsList-container {
    padding: px2rem(44px) 0 px2rem(56px);
    background-color: #f5f5f5;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .newsList-content {
        .news-list {
            padding-bottom: 20px;
            li {
                position: relative;
                //box-shadow: 0 10px 14px 0px rgba(0, 0, 0, .1);
                &:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .right-box {
                        .box-top {
                            .info {
                                h3 {
                                    color: #fc5556;
                                }
                            }
                            .time {
                                //background-color: #fc5556;
                                .date {
                                    color: #fff;
                                }
                                .year {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
                .news-box {
                    position: relative;
                    display: flex;
                    background-color: #fff;
                    padding: 30px 58px 30px 30px;
                    overflow: hidden;
                    z-index: 5;
                    @media screen and (max-width: 1260px) {
                        padding: 30px 40px 30px 30px;
                    }
                    @media screen and (max-width: 750px) {
                        padding: px2rem(30px);
                    }
                }
                .img {
                    width: 201px;
                    margin-right: px2rem(28px);
                    @media screen and (max-width: 1260px) {
                        width: 200px;
                    }
                    @media screen and (max-width: 750px) {
                        width: 150px;
                        margin-right: px2rem(30px);
                    }
                    @media screen and (max-width: 500px) {
                        width: 110px;
                        margin-right: 10px;
                    }
                    span {
                        display: block;
                        width: 100%;
                        padding-bottom: (129 / 201) * 100%;
                        position: relative;
                        overflow: hidden;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        transition: all .3s;
                    }
                }
                .right-box {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    //justify-content: center;
                    .box-top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 12px;
                        .info {
                            margin-right: 42px;
                            @media screen and (max-width: 1260px) {
                                margin-right: px2rem(30px);
                            }
                            @media screen and (max-width: 750px) {
                                margin-right: px2rem(30px);
                            }
                        }
                        h3 {
                            font-size: 20px;
                            line-height: 20px;
                            height: 20px;
                            @include multiEllipsis(1);
                            overflow: hidden;
                            color: #333;
                            margin-bottom: 13px;
                            transition: all .5s ease;
                            @media screen and (max-width: 500px) {
                                font-size: 16px;
                                line-height: 16px;
                                height: 16px;
                                margin-bottom: 6px;
                            }
                        }
                        .p {
                            font-size: 14px;
                            line-height: 28px;
                            color: #777;
                            height: 56px;
                            overflow: hidden;
                            @include multiEllipsis(2);
                            @media screen and (max-width: 750px) {
                                height: 56px;
                            }
                            @media screen and (max-width: 500px) {
                                font-size: 13px;
                                line-height: 18px;
                                height: 36px;
                            }
                        }
                        .time {
                            flex-shrink: 0;
                            width: 85px;
                            height: 85px;
                            color: #fff;
                            background-color: $baseColor;
                            text-align: center;
                            font-family: "Arial";
                            transition: all .2s ease;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            @media screen and (max-width: 750px) {
                                display: none;
                            }
                        }
                        .year {
                            text-align: center;
                            font-size: 14px;
                            line-height: 14px;
                            margin-top: 5px;
                            font-family: Arial;
                        }
                        .date {
                            text-align: center;
                            font-size: 36px;
                            line-height: 34px;
                        }
                    }
                }
            }
            li + li {
                margin-top: px2rem(28px);
            }
        }
    }
    .paged {
        margin-top: px2rem(44px);
    }
}