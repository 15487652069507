.produce-container {
    background-color: #f5f5f5;
    padding: px2rem(38px) 0 px2rem(76px);
    .wp1400 {
        overflow: hidden;
    }
    .produce-content {
        display: flex;
        flex-wrap: wrap;
        margin: -13px -11px;
        @media screen and (max-width: 1360px) {
            margin: -8px;
        }
        .content-box {
            width: 25%;
            padding: 13px 11px;
            box-sizing: border-box;
            @media screen and (max-width: 1360px) {
                padding: 8px;
            }
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .box {
                background-color: #fff;
                padding-bottom: px2rem(44px);
                box-sizing: border-box;
                cursor: pointer;
                @media screen and (max-width: 1360px) {
                    padding-bottom: px2rem(36px);
                }
                .img-box {
                    display: block;
                    width: 100%;
                    padding: 10px;
                    box-sizing: border-box;
                    .img {
                        width: 100%;
                        position: relative;
                        padding-bottom: 69%;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                            transition: all .3s;
                        }
                    }
                }
                .name {
                    display: block;
                    box-sizing: border-box;
                    padding: px2rem(20px) 10px 0;
                    .b {
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        transition: all .3s;
                        @media screen and (max-width: 1000px) {
                            font-size: 14px;
                        }
                    }
                    .s {
                        margin-top: 3px;
                        font-size: 14px;
                        color: #777;
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            font-size: 12px;
                        }
                    }
                }
                .btn-add {
                    margin-top: px2rem(26px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    @media screen and (max-width: 1360px) {
                        margin-top: px2rem(20px);
                    }
                    span {
                        display: block;
                    }
                    .icon {
                        width: 21px;
                        height: 21px;
                        line-height: 19px;
                        border: 1px solid #a8a8a8;
                        font-size: 12px;
                        box-sizing: border-box;
                        text-align: center;
                        color: #fff;
                        background-color: #fff;
                        margin-right: px2rem(8px);
                        @media screen and (max-width: 1360px) {
                            width: 20px;
                            height: 20px;
                            line-height: 14px;
                        }
                    }
                    .word {
                        font-size: 14px;
                        color: #666;
                        @media screen and (max-width: 1360px) {
                            font-size: 12px;
                        }
                    }
                }
                .btn-active {
                    .icon {
                        background-color: $baseColor;
                        border: 1px solid $baseColor;
                    }
                }
            }
        }
    }
    .paged {
        margin-top: px2rem(48px);
    }
}
.produce-class {
    width: 100%;
    margin-bottom: px2rem(48px);
    .box {
        display: block;
        font-size: 15px;
        line-height: 1;
        color: #555;
        background-color: #fff;
        padding: 14px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: all .3s;
        @media screen and (max-width: 1000px) {
            padding: 10px;
            font-size: 14px;
        }
    }
    .swiper-slide {
        width: auto;
    }
    .on {
        .box {
            background-color: $baseColor;
            color: #fff;
        }
    }

    .swiper-slide:hover {
        .box {
            background-color: $baseColor;
            color: #fff;
        }
    }
}
.balance-fixed {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    background-color: #fff;
    padding: 16px 0 58px;
    box-sizing: border-box;
    transition: all .5s;
    @media screen and (max-width: 1000px) {
        padding: 16px 0;
    }
    .top-btn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 25px;
        .box {
            font-size: 13px;
            color: #666;
            cursor: pointer;
        }
    }
    .fixed-content {
        display: flex;
        flex-wrap: wrap;
        padding-right: px2rem(16px);
        box-sizing: border-box;
        @media screen and (max-width: 1000px) {
            padding-right: 0;
            justify-content: center;
        }
        .content-left {
            //flex: 1;
            display: flex;
            width: calc(100% - 102px);
            padding-right: px2rem(36px);
            box-sizing: border-box;
            @media screen and (max-width: 1360px) {
                padding-right: 10px;
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
                padding-right: 0;
            }
            .balance-swiper {
                width: 100%;
            }
            .content-box {
                flex: 1;
                display: flex;
                align-items: center;
                position: relative;
                padding-right: 9px;
                box-sizing: border-box;
                border-right: 1px solid #e5e5e5;
                .img-box {
                    width: 72px;
                    border: 1px solid #e1e1e1;
                    box-sizing: border-box;
                    margin-right: 15px;
                    .img {
                        width: 100%;
                        position: relative;
                        padding-bottom: 100%;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 80%;
                            max-height: 80%;
                            transition: all .3s;
                        }
                    }
                }
                .info {
                    font-size: 12px;
                    line-height: 20px;
                    color: #444;
                }
                .guanbi {
                    position: absolute;
                    color: #9c9c9c;
                    top: 0;
                    right: 9px;
                    font-size: 12px;
                    cursor: pointer;
                    @media screen and (max-width: 1000px) {
                        right: 2px;
                    }
                }
                .guanbi:hover {
                    color: $baseColor;
                }
            }
            .content-box:hover {
                .img-box {
                    .img {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
            }
        }
        .content-right {
            width: 102px;
            @media screen and (max-width: 1000px) {
                margin-top: px2rem(36px);
            }
            .go-table {
                display: block;
                height: 32px;
                border-radius: 3px;
                width: 100%;
                background-color: $baseColor;
                text-align: center;
                line-height: 32px;
                color: #fff;
                font-size: 14px;
                transition: all .3s;
            }
            .go-table:hover {
                opacity: .8;
            }
            .btn {
                cursor: pointer;
                text-align: center;
                font-size: 13px;
                line-height: 1;
                color: #b2b2b2;
                margin-top: 10px;
            }
            .btn:hover {
                cursor: pointer;
            }
        }
    }
}

.fixed-hide {
    display: none;
    position: fixed;
    z-index: 10;
    bottom: 50px;
    right: 0;
    padding: 10px;
    box-sizing: border-box;
    background-color: $baseColor;
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    .bit {
        font-family: 宋体;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        transition: all .3s;
    }
}
.fixed-hide-active {
    display: block;
}
.fixed-hide:hover {
    opacity: .8;
}
.fixed-show {
    transform: translateY(0);
}