.footer {
    background-color: #303030;
    .footer-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 60px 0 80px;
        box-sizing: border-box;
        @media screen and (max-width: 1000px) {
            padding: px2rem(80px) 0;
            display: flex;
            justify-content: center;
        }
        .content-left {
            width: 40%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .phone {
                display: flex;
                align-items: center;
                .img {
                    margin-right: px2rem(8px);
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                .info {
                    flex: 1;
                    .s {
                        font-size: 16px;
                        line-height: 30px;
                        color: #fff;
                    }
                    .b {
                        font-size: 28px;
                        line-height: 30px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #fff;
                    }
                }
            }
            .dsc {
                margin-top: 5px;
                font-size: 13px;
                line-height: 30px;
                color: #929292;
            }
        }
        .content-right {
            width: 45%;
            @media screen and (max-width: 1000px) {
                width: 100%;
                margin-top: px2rem(30px);
            }
            .link {
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1000px) {
                    justify-content: flex-start;
                }
                .name {
                    font-size: 13px;
                    line-height: 24px;
                    color: #fff;
                    white-space: nowrap;
                }
                .link-content {
                    display: flex;
                    flex-wrap: wrap;
                    .box {
                        display: block;
                        font-size: 13px;
                        line-height: 24px;
                        color: #fff;
                        padding: 0 10px;
                        box-sizing: border-box;
                        position: relative;
                        transition: all .3s;
                    }
                    .box:hover {
                        text-decoration: underline;
                    }
                    .box:before {
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 6px;
                        background-color: #6f6f6f;
                    }
                    .box:nth-last-of-type(1):before {
                        content: none;
                    }
                }
            }
            .info {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                font-size: 13px;
                line-height: 24px;
                color: #929292;
                @media screen and (max-width: 1000px) {
                    justify-content: flex-start;
                    display: none;
                }
                span {
                    display: block;
                }
                a {
                    display: block;
                    font-size: 13px;
                    line-height: 24px;
                    color: #929292;
                }
            }
            .ewm-content {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1000px) {
                    justify-content: center;
                    margin-top: px2rem(30px);
                }
                .box {
                    position: relative;
                    margin-right: 15px;
                    cursor: pointer;
                    @media screen and (max-width: 500px) {
                        margin-right: 10px;
                    }
                    .circle {
                        position: relative;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background-color: #fff;
                        line-height: 32px;
                        font-size: 18px;
                        color: #383838;
                        text-align: center;
                        margin: 0;
                        text-indent: 0px;
                        background-image: none;
                    }
                    .circle:after {
                        position: absolute;
                        z-index: 9;
                        content: none;
                        top: -23px;
                        border-width: 6px 11px 6px 0;
                        right: 50%;
                        -webkit-transform: translateX(50%);
                        -moz-transform: translateX(50%);
                        -ms-transform: translateX(50%);
                        transform: translateX(50%);
                        border-top: 15px solid #f5f5f5;
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                    }
                    .code {
                        position: absolute;
                        z-index: 2;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 55px;
                        display: none;
                        padding: 22px 20px 10px;
                        background-color: #d9d9d9;
                        box-sizing: border-box;
                        border-radius: 5px;
                        transition: all .3s;
                        width: auto;
                        @media screen and (max-width: 1800px) {
                            flex-wrap: wrap;
                        }
                        @media screen and (max-width: 1000px) {
                            flex-wrap: wrap;
                            padding: 10px;
                            width: auto;
                        }
                        @media screen and (max-width: 500px) {
                            bottom: 55px;
                        }
                        .code-box {
                            width: 100px;
                            margin-right: 10px;
                            @media screen and (max-width: 1800px) {
                                margin-right: 0px;
                                margin-bottom: 10px;
                            }
                            @media screen and (max-width: 500px) {
                                width: 70px;
                            }
                            img {
                                display: block;
                                max-width: 100%;
                                height: auto;
                            }
                            p {
                                margin-top: 5px;
                                display: block;
                                font-size: 14px;
                                line-height: 14px;
                                color: #666;
                                text-align: center;
                            }
                        }
                        .code-box:nth-last-of-type(1) {
                            margin-right: 0px;
                            @media screen and (max-width: 1800px) {
                                margin-bottom: 10px;
                            }
                        }
                    }

                }
                .box:hover {
                    .circle:after {
                        content: '';
                    }
                    .code {
                        display: flex;
                    }
                }
                .no-box:hover {
                    .circle:after {
                        content: none;
                    }
                }
                .box:nth-last-of-type(1) {
                    margin-right: 0;
                }
            }
        }
    }
}
