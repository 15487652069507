@charset 'utf-8';
/* CSS Document */
@import url('//at.alicdn.com/t/font_2653789_acgun2qmeht.css');
//SCSS全局变量
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
$baseColor: #f15d0b;
$onColor: #f15d0b;
@mixin baseImg() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function bfb($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
// 多行省略
@mixin multiEllipsis($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
/* reset.css V1.6  Start*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px '微软雅黑', BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp {
    width: 1000px;
    margin: 0 auto;
}
.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */
// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="prev">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="next" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
.paged {
    text-align: center;
    @media screen and (max-width: 750px) {
        display: flex;
        justify-content: center;
    }
}
.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    margin: 0 3px;
    font-size: 13px;
    text-align: center;
    background: #fff;
    color: #595959;
    font-family: "Myriad Pro";
    height: 36px;
    width: 36px;
    line-height: 40px;
    @media screen and (max-width: 750px) {
        display: none;
    }
    @media screen and (max-width: 500px) {
        padding: 0 10px;
        margin: 0 5px 2px;
        height: 35px;
        line-height: 35px;
    }
}
.paged .prev,
.paged .next {
    width: 105px;
    font-size: 15px;
    @media screen and (max-width: 750px) {
        width: 40%;
        display: block;
    }
}
.paged .current,
.paged a:hover {
    background: $baseColor !important;
    color: #fff !important;
}

.wp1400 {
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1420px) {
        padding: 0 px2rem(52px);
    }
    @media screen and (max-width: 1000px) {
        padding: 0 px2rem(30px);
    }
}

.wp1360 {
    max-width: 1360px;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1380px) {
        padding: 0 px2rem(52px);
    }
    @media screen and (max-width: 1000px) {
        padding: 0 px2rem(30px);
    }
}

.video-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9001;
    &.show {
        .video-popup-box,
        .video-popup-mask {
            opacity: 1;
        }
        .video-popup-box {
            transform: translate(-50%, -50%) scale(1);
        }
    }
    &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1340px;
        background-color: #000;
        box-shadow: 0 6px 20px rgba(0, 0, 0, .8);
        opacity: 0;
        z-index: 6;
        transform: translate(-50%, -50%) scale(.8);
        transition: all .4s;
    }
    .video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (638 / 1340) * 100%;
        overflow: hidden;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
        }
    }
    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .9);
        z-index: 2;
        opacity: 0;
        transition: all .4s;
    }
    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $baseColor;
        font-size: 24px;
        cursor: pointer;
        transition: all .3s;
        z-index: 9;
        &:hover {
            transform: rotate(180deg);
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            width: calc(100% - 100px);
        }
    }
    @media screen and (max-width: 750px) {
        &.show {
            .video-popup-box {
                transform: scale(1);
            }
        }
        &-box {
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            transform: scale(.8);
        }
        .video {
            padding-bottom: 60%;
        }
        &-close {
            top: px2rem(40px);
            right: px2rem(40px);
            font-size: px2rem(40px);
        }
    }
}

//弹窗页面的css
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .same {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        border: 1px solid $onColor;
        background-color: $onColor;
        text-align: center;
        color: #fff;
        outline: none;
        transition: all .3s;
        cursor: pointer;
        box-sizing: border-box;
        @media screen and (max-width: 750px) {
            width: 20px;
            height: 20px;
            line-height: 20px;
        }
    }
    .same:hover {
        opacity: .8;
    }
    .honor-next {
        right: 10vw;
        @media screen and (max-width: 1500px) {
            right: 5vw
        }
    }
    .honor-prev {
        left: 10vw;
        transform: translateY(-50%) rotate(180deg);
        @media screen and (max-width: 1500px) {
            left: 5vw
        }

    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}
