.table-container {
    padding: px2rem(36px) 0 px2rem(45px);
    background-color: #f5f5f5;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .table-title {
        text-align: center;
        font-size: 30px;
        color: #333;
        @media screen and (max-width: 1360px) {
            font-size: 22px;
        }
    }
    .wp1360 {
        overflow: hidden;
    }
    .table-content {
        width: 100%;
        background-color: #fff;
        margin-top: px2rem(30px);
        @media screen and (max-width: 1360px) {
            overflow-X: scroll;
        }
        .top-content {
            display: flex;
            .content-box {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #e8e8e8;
                border-bottom: 1px solid #e8e8e8;
                box-sizing: border-box;
                min-width: 140px;
                .span {
                    font-size: 16px;
                    color: #555;
                }
                .box {
                    width: 100%;
                    padding-bottom: px2rem(40px);
                    box-sizing: border-box;
                    @media screen and (max-width: 1360px) {
                        padding-bottom: px2rem(30px);
                    }
                    .img-box {
                        width: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                        //@media screen and (max-width: 1360px) {
                        //    padding: 5px;
                        //}
                        .img {
                            width: 100%;
                            position: relative;
                            padding-bottom: 60%;
                            overflow: hidden;
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                max-width: 100%;
                                max-height: 100%;
                                transition: all .3s;
                            }
                        }
                    }
                    .name {
                        margin-top: 10px;
                        box-sizing: border-box;
                        padding: 0 10px;
                        .b {
                            text-align: center;
                            font-size: 16px;
                            font-weight: bold;
                            color: #333;
                            transition: all .3s;
                            @media screen and (max-width: 1000px) {
                                font-size: 14px;
                            }
                        }
                        .s {
                            margin-top: 3px;
                            font-size: 14px;
                            color: #777;
                            text-align: center;
                            @media screen and (max-width: 1000px) {
                                font-size: 12px;
                            }
                        }
                    }
                    .go-info {
                        display: block;
                        margin: 15px auto 0;
                        width: 100px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 14px;
                        color: #777;
                        background-color: #f1f1f1;
                        transition: all .3s;
                        @media screen and (max-width: 1360px) {
                            margin: 10px auto 0;
                            width: 80px;
                            height: 25px;
                            line-height: 25px;
                            font-size: 12px;
                        }
                    }
                    .go-info:hover {
                        background-color: $baseColor;
                        color: #fff;
                    }
                }
            }
            .first-box {
                width: 222px;
                flex: none;
                min-width: auto;
                @media screen and (max-width: 1360px) {
                    width: 180px;
                }
                @media screen and (max-width: 750px) {
                    width: 120px;
                }
            }
            .content-box:nth-last-of-type(1) {
                border-right: 0;
            }
        }
        .info-container {
            width: 100%;
            .line-content-dis {
                .box {
                    background-color: #fffee5 !important;
                }
            }
            .line-content {
                display: flex;
                .box {
                    background-color: #f7f7f7;
                    flex: 1;
                    padding: 14px 14px 14px 28px;
                    box-sizing: border-box;
                    border-right: 1px solid #e8e8e8;
                    border-bottom: 1px solid #e8e8e8;
                    min-width: 140px;
                    @media screen and (max-width: 1360px) {
                        padding: 10px;
                    }
                    .span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #666;
                    }
                }
                .box:nth-last-of-type(1) {
                    border-right: 0;
                }
                .first-box {
                    background-color: #f7f7f7 !important;
                    width: 222px;
                    flex: none;
                    min-width: auto;
                    @media screen and (max-width: 1360px) {
                        width: 180px;
                    }
                    @media screen and (max-width: 750px) {
                        width: 120px;
                    }
                }
            }
            .line-content:nth-child(2n+2) {
                .box {
                    background-color: #fff;
                }
                .first-box {
                    background-color: #fff !important;
                }
            }

        }
    }
}