.solution-container {
    background-color: #f5f5f5;
    padding: px2rem(62px) 0 px2rem(78px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .solution-content {
        width: 100%;
    }
    .solution-top {
        display: flex;
        //align-items: center;
        flex-wrap: wrap;
        padding: 46px 80px 52px 46px;
        box-sizing: border-box;
        background-color: #fff;
        @media screen and (max-width: 1360px) {
            padding: px2rem(40px) px2rem(30px);
            align-items: center;
        }
        .content-left {
            position: relative;
            width: (594 / 1234) * 100%;
            box-sizing: border-box;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .solution-swiper {
                width: 100%;
                .box {
                    width: 100%;
                    cursor: pointer;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (404 / 594) * 100%;
                        img {
                            @include baseImg;
                        }
                    }
                }
            }
            .same {
                position: absolute;
                z-index: 2;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                width: 34px;
                height: 34px;
                line-height: 34px;
                background-color: $onColor;
                text-align: center;
                color: #fff;
                outline: none;
                transition: all .3s;
                cursor: pointer;
                box-sizing: border-box;
                @media screen and (max-width: 1360px) {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                }
                @media screen and (max-width: 1360px) {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                }
            }
            .same:hover {
                background-color: $baseColor;
            }
            .img-next {
                right: 0px;
                background-color: #fd7c35;
            }
            .img-prev {
                right: 34px;
                transform: rotate(180deg);
                background-color: #f15d0b;
            }
        }
        .content-right {
            flex: 1;
            position: relative;
            padding-left: 75px;
            padding-top: 70px;
            box-sizing: border-box;
            @media screen and (max-width: 1360px) {
                padding-left: 30px;
                padding-top: 0;
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
                padding-left: 0;
                margin-top: px2rem(40px);
            }
            .title {
                font-size: 26px;
                font-weight: bold;
                line-height: 1;
                color: #333;
                padding-bottom: px2rem(24px);
                border-bottom: 1px dashed #777;
                box-sizing: border-box;
                @media screen and (max-width: 1360px) {
                    font-size: 20px;
                }
            }
            .dsc {
                margin-top: px2rem(16px);
                font-size: 14px;
                line-height: 30px;
                color: #666;
                @include articleReset(14px, 30px);
                @media screen and (max-width: 1360px) {
                    font-size: 13px;
                    line-height: 20px;
                    @include articleReset(13px, 20px);
                }
            }
            .btn {
                position: absolute;
                bottom: 0;
                left: 75px;
                display: block;
                transition: all .3s;
                @media screen and (max-width: 1360px) {
                    position: static;
                    margin-top: px2rem(30px);
                }
                @media screen and (max-width: 750px) {
                    text-align: center;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    @media screen and (max-width: 750px) {
                        width: 140px;
                    }
                }
            }
            .btn:hover {
                opacity: .8;
            }
        }
    }
    .solution-middle {
        background-color: #fff;
        line-height: 30px;
        @include articleReset(14px, 30px);
        padding: 0 80px 52px 46px;
        box-sizing: border-box;
        color: #666;
        @media screen and (max-width: 1360px) {
            padding: 0 px2rem(30px) px2rem(40px);
            align-items: center;
            font-size: 13px;
            line-height: 20px;
            @include articleReset(13px, 20px);
        }
    }
    .solution-bottom {
        margin-top: 50px;

        .bottom-content {
            position: relative;
            margin-top: px2rem(40px);
            .box {
                display: block;
                width: 100%;
                background-color: #fff;
                padding-bottom: px2rem(84px);
                box-sizing: border-box;
                cursor: pointer;
                @media screen and (max-width: 1360px) {
                    padding-bottom: px2rem(36px);
                }
                .img-box {
                    width: 100%;
                    padding: 10px;
                    box-sizing: border-box;
                    .img {
                        width: 100%;
                        position: relative;
                        padding-bottom: 69%;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                            transition: all .3s;
                        }
                    }
                }
                .name {
                    margin-top: px2rem(20px);
                    box-sizing: border-box;
                    padding: 0 10px;
                    .b {
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        transition: all .3s;
                        @media screen and (max-width: 1000px) {
                            font-size: 14px;
                        }
                    }
                    .s {
                        margin-top: 3px;
                        font-size: 14px;
                        color: #777;
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            font-size: 12px;
                        }
                    }
                }
            }
            .box:hover {
                .img-box {
                    .img {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
            }
            .same {
                position: absolute;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                line-height: 30px;
                border: 1px solid #787878;
                //background-color: $onColor;
                text-align: center;
                color: #333;
                outline: none;
                transition: all .3s;
                cursor: pointer;
                box-sizing: border-box;
                @media screen and (max-width: 750px) {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                }
            }
            .same:hover {
                background-color: $baseColor;
                border: 1px solid $baseColor;
                color: #fff;
            }
            .produce-next {
                right: -50px;
                @media screen and (max-width: 1500px) {
                    right: -30px
                }
                @media screen and (max-width: 1360px) {
                    right: 0px;
                }
            }
            .produce-prev {
                left: -50px;
                transform: translateY(-50%) rotate(180deg) ;
                @media screen and (max-width: 1500px) {
                    left: -30px
                }
                @media screen and (max-width: 1360px) {
                    left: 0px;
                }
            }
        }
    }
}
.solution-title {
    text-align: center;
    font-size: 26px;
    line-height: 1;
    font-weight: bold;
    color: #333;
    @media screen and (max-width: 1360px) {
        font-size: 20px;
    }
}

.video-container {
    padding: 52px 0 86px;
    box-sizing: border-box;
    background-color: #fff;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .solution-title {
        margin-bottom: px2rem(38px);
    }
    .video-content {
        display: flex;
        flex-wrap: wrap;
        margin: -16px;
        @media screen and (max-width: 1360px) {
            margin: -8px;
        }
        .content-box {
            width: 33.3%;
            padding: 16px;
            box-sizing: border-box;
            @media screen and (max-width: 1360px) {
                padding: 8px;
            }
            @media screen and (max-width: 1000px) {
                width: 50%;
            }
            .box {
                display: block;
                width: 100%;
                cursor: pointer;
                .img-box {
                    width: 100%;
                    position: relative;
                    .img {
                        position: relative;
                        padding-bottom: (281 / 431) * 100%;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .btn {
                        position: absolute;
                        bottom: 16px;
                        left: 14px;
                        @media screen and (max-width: 1360px) {
                            bottom: 5px;
                            left: 5px;
                        }
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            @media screen and (max-width: 1360px) {
                                max-width: 80%;
                                max-height: 80%;
                            }
                            @media screen and (max-width: 750px) {
                                max-width: 60%;
                                max-height: 60%;
                            }
                        }
                    }
                }
                .name {
                    font-size: 18px;
                    line-height: 1;
                    color: #555;
                    margin-top: 25px;
                    padding-left: 17px;
                    box-sizing: border-box;
                    transition: all .3s;
                    @media screen and (max-width: 1360px) {
                        font-size: 15px;
                        padding-left: 5px;
                        margin-top: 10px;
                    }
                }
            }
            .box:hover {
                .img-box {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                .name {
                    color: $baseColor;
                }
            }
        }
    }
}