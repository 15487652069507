.produceInfo-container {
    background-color: #f5f5f5;
    padding: px2rem(38px) 0 px2rem(76px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .wp1360 {
        overflow: hidden;
    }
    .produceInfo-content {
        margin-top: px2rem(60px);
        padding: 0 120px 0 85px;
        box-sizing: border-box;
        @media screen and (max-width: 1360px) {
            padding: 0 px2rem(30px);
        }
        @media screen and (max-width: 1000px) {
            padding: 0;
        }
        .content-top {
            display: flex;
            flex-wrap: wrap;
            .content-left {
                width: (525 / 1155) * 100%;
                @media screen and (max-width: 1000px) {
                    width: 100%;
                    order: 2;
                    margin-top: px2rem(30px);
                }
                .parts-top {
                    width: 100%;
                    .swiper-slide {
                        border: 1px solid #e1e1e1;
                        box-sizing: border-box;
                        background-color: #fff;
                        position: relative;
                    }
                    .img {
                        position: relative;
                        padding-bottom: 100%;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                            transition: all .3s;
                        }
                    }
                    .name {
                        position: absolute;
                        bottom: px2rem(20px);
                        width: 90%;
                        left: 50%;
                        transform: translateX(-50%);
                        overflow: hidden;
                        font-size: 24px;
                        line-height: 24px;
                        height: 24px;
                        color: #333;
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            font-size: 14px;
                            line-height: 14px;
                            height: 14px;
                        }
                    }
                    .img:hover {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
            }
            .content-right {
                flex: 1;
                padding-left: 70px;
                box-sizing: border-box;
                @media screen and (max-width: 1360px) {
                    padding-left: 30px;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                    order: 1;
                    padding-left: 0;
                }
                .title {
                    width: 100%;
                    padding-bottom: 24px;
                    border-bottom: 1px dashed #a7a7a7;
                    @media screen and (max-width: 1360px) {
                        padding-bottom: 15px;
                    }
                    .b {
                        font-size: 36px;
                        font-weight: bold;
                        line-height: 1;
                        color: #333;
                        @media screen and (max-width: 1360px) {
                            font-size: 24px;
                        }
                    }
                    .s {
                        margin-top: 3px;
                        font-size: 18px;
                        color: #777;
                        @media screen and (max-width: 1360px) {
                            font-size: 14px;
                        }
                    }
                }
                .dsc {
                    margin-top: px2rem(14px);
                    font-size: 14px;
                    line-height: 36px;
                    color: #777;
                    @media screen and (max-width: 1360px) {
                        font-size: 13px;
                        line-height: 28px;
                    }
                    p {
                        position: relative;
                        padding-left: 15px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1360px) {
                            padding-left: 10px;
                        }
                    }
                    p:before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        top: 14px;
                        left: 0;
                        background-color: $baseColor;
                        @media screen and (max-width: 1360px) {
                            width: 5px;
                            height: 5px;
                            top: 11px;
                        }
                    }
                }
            }
        }
        .img-swiper {
            margin-top: 10px;
            position: relative;
            box-sizing: border-box;
            .img-lunbo {
                .swiper-slide {
                    width: auto;
                    .img {
                        position: relative;
                        width: 78px;
                        padding-bottom: 100%;
                        overflow: hidden;
                        cursor: pointer;
                        border: 1px solid #e1e1e1;
                        box-sizing: border-box;
                        background-color: #fff;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 80%;
                            max-height: 80%;
                            transition: all .3s;
                        }
                    }
                    .name {
                        margin-top: px2rem(12px);
                        font-size: 14px;
                        line-height: 25px;
                        color: #333;
                        text-align: center;
                        @media screen and (max-width: 1360px) {
                            font-size: 13px;
                            line-height: 20px;
                        }
                    }
                }
                .swiper-slide-thumb-active {
                    .img {
                        border: 1px solid $baseColor;
                    }
                }
            }
        }
    }
}
.link-container {
    padding-bottom: px2rem(64px);
    .link-swiper {
        border-bottom: 1px solid #e1e1e1;
        box-sizing: border-box;
        margin-bottom: px2rem(48px);
        .box {
            position: relative;
            display: block;
            font-size: 18px;
            line-height: 36px;
            color: #333;
            padding: 35px 45px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
            transition: all .3s;
            @media screen and (max-width: 1000px) {
                padding: 20px;
                font-size: 14px;
            }
        }
        .box:before {
            position: absolute;
            content: '';
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 11px;
            background-color: #ccc;
        }
        .box:after {
            position: absolute;
            content: '';
            bottom: 0px;
            left: 0;
            width: 0;
            height: 3px;
            background-color: $baseColor;
            transition: all .3s;
        }
        .swiper-slide {
            width: auto;
        }
        .swiper-slide:nth-last-of-type(1) {
            .box:before {
                content: none;
            }
        }
        //.swiper-slide-active {
        //    .box {
        //        color: $baseColor;
        //    }
        //    .box:after {
        //        width: 100%;
        //    }
        //}
        .on {
            .box {
                color: $baseColor;
            }
            .box:after {
                width: 100%;
            }
        }
        .swiper-slide:hover {
            .box {
                color: $baseColor;
            }
            .box:after {
                width: 100%;
            }
        }
    }
    .link-content {
        width: 100%;
        position: relative;
        display: none;
        flex-wrap: wrap;
        font-size: 14px;
        //line-height: 26px;
        color: #000;
        @include articleReset(14px, 26px);
        .swiper-container {
            width: 100%;
            .swiper-slide {
                display: block;
                cursor: pointer;
            }
            .swiper-slide:hover {
                .img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .img {
            width: 100%;
            position: relative;
            padding-bottom: (198 / 291) * 100%;
            overflow: hidden;
            img {
                @include baseImg;
                transition: all .3s;
            }
        }
        .name {
            margin-top: px2rem(6px);
            text-align: center;
            padding: 0 10px;
            box-sizing: border-box;
            font-size: 18px;
            line-height: 36px;
            color: #000;
            @media screen and (max-width: 1360px) {
                font-size: 15px;
                line-height: 30px;
            }
        }
        .same {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 30px;
            border: 1px solid $onColor;
            background-color: $onColor;
            text-align: center;
            color: #fff;
            outline: none;
            transition: all .3s;
            cursor: pointer;
            box-sizing: border-box;
            @media screen and (max-width: 750px) {
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
        }
        .same:hover {
            opacity: .8;
        }
        .produce-next {
            right: -50px;
            @media screen and (max-width: 1500px) {
                right: -30px
            }
            @media screen and (max-width: 1360px) {
                right: 0px;
            }
        }
        .produce-prev {
            left: -50px;
            transform: translateY(-50%) rotate(180deg);
            @media screen and (max-width: 1500px) {
                left: -30px
            }
            @media screen and (max-width: 1360px) {
                left: 0px;
            }
        }
        table {
            p {
                margin: 5px 0 !important;
                text-indent: 0 !important;
            }
        }
        //.content-box {
        //    width: 25%;
        //    padding: 32px;
        //    box-sizing: border-box;
        //    @media screen and (max-width: 1360px) {
        //        padding: 15px;
        //    }
        //    @media screen and (max-width: 1000px) {
        //        padding: 10px;
        //    }
        //    @media screen and (max-width: 750px) {
        //        width: 50%;
        //    }
        //
        //}
    }
    .link-content-active {
        display: block;
    }
}

