.culture-container {
    background-color: #f5f5f5;
    padding: px2rem(38px) 0 px2rem(100px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .produce-class {
        margin-bottom: px2rem(70px);
    }
    .culture-content {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1000px) {
            display: block;
        }
        .content-right {
            width: (636 / 1360) * 100%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }

        .content-left {
            flex: 1;
            font-size: 14px;
            line-height: 28px;
            color: #777;
            @include articleReset(14px,50px);
            padding-right: 40px;
            box-sizing: border-box;
            @media screen and (max-width: 1000px) {
                padding-right: 0;
                margin-bottom: px2rem(40px);
                font-size: 13px;
                line-height: 26px;
                color: #777;
                @include articleReset(13px,30px);
            }
        }
    }
}

