.service-container {
    background-color: #f5f5f5;
    padding: px2rem(38px) 0 px2rem(76px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
}

.problem-list-wrap {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 35px;

    @media screen and (max-width: 1024px) {
        padding: 20px 0;
        box-sizing: border-box;
    }
    .inner-problem-list {
        position: relative;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, .1);

    }
    .problem-list {
        position: relative;
        width: 100%;
        padding-left: 30px;
        padding-right: 28px;
        background-color: #fff;
        z-index: 2;
        cursor: pointer;
        box-sizing: border-box;
        &:nth-child(2n) {
            background-color: #f8f8f8;
        }
        &:not(:last-child) {
            border-bottom: 1px dashed rgba(0, 0, 0, .1);
        }
        &:hover,
        &.on {
            .icon-box i {
                background-color: $baseColor;
            }
            .span-q {
                background-color: $baseColor;
            }
        }
        &.on {
            .arrow-box i {
                transform: rotate(180deg);
            }
        }
        @media screen and (max-width: 1024px) {
            padding: 10px 20px;
        }
    }
    .item {
        width: 100%;
        height: 76px;
        @media screen and (max-width: 1024px) {
            display: flex;
            height: 40px;
        }
    }
    .icon-box {
        float: left;
        height: 100%;
        line-height: 76px;
        @media screen and (max-width: 1024px) {
            float: none;
            line-height: 40px;
        }
        i,
        .span-q {
            display: inline-block;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-size: 18px;
            background-color: $baseColor;
            border-radius: 50%;
            vertical-align: middle;
            font-family: 宋体;
            @include transition();
            @media screen and (max-width: 1024px) {
                width: 25px;
                height: 25px;
                line-height: 25px;
                font-size: 18px;
            }
        }
    }
    .txt-box {
        float: left;
        width: 90%;
        height: 100%;
        padding-left: 10px;
        line-height: 76px;
        font-size: 14px;
        color: #777;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        box-sizing: border-box;
        @media screen and (max-width: 1024px) {
            float: none;
            flex: 1;
            width: 0;
            padding: 0 10px;
            line-height: 40px;
            font-size: 14px;
        }
    }
    .arrow-box {
        float: right;
        height: 100%;
        line-height: 76px;
        i {
            display: inline-block;
            font-size: 26px;
            color: #ababab;
            vertical-align: middle;
            @include transition();
        }
        @media screen and (max-width: 1024px) {
            float: none;
            line-height: 40px;
            i {
                font-size: 16px;
            }
        }
    }
    .sub {
        display: none;
        padding-bottom: 68px;
        margin: 0 75px;
        line-height: 30px;
        font-size: 14px;
        color: #777;
        @media screen and (max-width: 1024px) {
            padding-bottom: 15px;
            margin: 0;
        }
        p {
            line-height: 30px;
            font-size: 14px;
            color: #777;
            @media screen and (max-width: 1024px) {
                font-size: 13px;
            }
        }
    }
}