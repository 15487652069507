.videoList-container {
    padding: 68px 0 52px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .wp1360 {
        overflow: hidden;
    }
    .videoList-content {
        display: flex;
        flex-wrap: wrap;
        margin: -20px -16px;
        @media screen and (max-width: 1360px) {
            margin: -10px;
        }
        .content-box {
            width: 33.3%;
            padding: 20px 16px;
            box-sizing: border-box;
            @media screen and (max-width: 1360px) {
                padding: 10px;
            }
            @media screen and (max-width: 1000px) {
                width: 50%;
            }
            .box {
                display: block;
                width: 100%;
                cursor: pointer;
                .img-box {
                    width: 100%;
                    position: relative;
                    .img {
                        position: relative;
                        padding-bottom: (281 / 431) * 100%;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .btn {
                        position: absolute;
                        bottom: 16px;
                        left: 14px;
                        @media screen and (max-width: 1360px) {
                            bottom: 5px;
                            left: 5px;
                        }
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            @media screen and (max-width: 1360px) {
                                max-width: 80%;
                                max-height: 80%;
                            }
                            @media screen and (max-width: 750px) {
                                max-width: 60%;
                                max-height: 60%;
                            }
                        }
                    }
                }
                .name {
                    font-size: 18px;
                    color: #555;
                    height: 72px;
                    line-height: 72px;
                    @include multiEllipsis(1);
                    padding-left: px2rem(16px);
                    box-sizing: border-box;
                    transition: all .3s;
                    box-shadow: 0 5px 30px -5px rgba(0, 0, 0, .2);
                    background-color: #fff;
                    @media screen and (max-width: 1360px) {
                        font-size: 15px;
                        height: 50px;
                        line-height: 50px;
                    }
                    @media screen and (max-width: 1000px) {
                        font-size: 15px;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
            .box:hover {
                .img-box {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                .name {
                    color: $baseColor;
                }
            }
        }
    }
    .paged {
        margin-top: px2rem(54px);
        @media screen and (max-width: 1000px) {
            margin-top: 30px;
        }
    }
}