.index-banner {
    position: relative;
    .swiper-container {
        .swiper-slide {
            transition-duration: 1s !important;
            .img {
                width: 100%;
                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    padding-bottom: (674 / 1920) * 100%;
                    img {
                        @include baseImg;
                    }
                }
            }
        }
        .swiper-slide-active {
            .img {
                a {
                    img {
                        animation: bannerIn 20s forwards;
                    }
                }
            }
        }
    }
    .swiper-pagination {
        bottom: px2rem(32px);
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 500px) {
            bottom: 10px;
        }
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 5px;
            outline: none;
            background-color: #fff;
            opacity: 1;
            @media screen and (max-width: 500px) {
                width: 8px;
                height: 8px;
                margin: 0 4px;
            }
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
            background-color: $baseColor;
        }
    }
}
@keyframes bannerIn {
    100% {
        transform: scale(1.1);
    }
}
.pc-banner {
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    @media screen and (max-width: 500px) {
        padding-top: 60px;
    }
}
.mb-banner {
    display: none;
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        display: block;
        padding-top: 100px;
    }
    @media screen and (max-width: 500px) {
        padding-top: 60px;
    }
    .swiper-container {
        .swiper-slide {
            .img {
                a {
                    padding-bottom: (345 / 750) * 100%;
                }
            }
        }
    }
}
.index-product {
    padding: 88px 0;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .product-content {
        margin-top: px2rem(36px);
        display: flex;
        flex-wrap: wrap;
        border-right: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0;
        box-sizing: border-box;
        @media screen and (max-width: 1000px) {
            margin-top: 20px;
        }
        .content-box {
            display: block;
            width: 25%;
            box-sizing: border-box;
            border-left: 1px solid #d0d0d0;
            border-top: 1px solid #d0d0d0;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            @media screen and (max-width: 1100px) {
                width: 33.3%;
            }
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .box {
                width: 100%;
                padding-bottom: 67px;
                @media screen and (max-width: 1000px) {
                    padding-bottom: px2rem(60px);
                }
                .img-box {
                    padding: px2rem(10px);
                    box-sizing: border-box;
                    @media screen and (max-width: 1000px) {
                        padding: px2rem(30px);
                    }
                    .img {
                        position: relative;
                        padding-bottom: 70%;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                            transition: all .3s;
                        }
                    }
                }
                .name {
                    margin-top: px2rem(20px);
                    box-sizing: border-box;
                    @media screen and (max-width: 1000px) {
                        padding: 0 10px;
                    }
                    .b {
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        transition: all .3s;
                        @media screen and (max-width: 1000px) {
                            font-size: 14px;
                        }
                    }
                    .s {
                        margin-top: 3px;
                        font-size: 14px;
                        color: #777;
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .content-box:hover {
            .box {
                .img-box {
                    .img {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
                .name {
                    .b {
                        color: $baseColor;
                    }
                }
            }
        }
        .box-bk1 {
            background-color: #fafafa;
        }
        .box-bk2 {
            background-color: #f5f5f5;
        }
        .box-bk4 {
            border-top: 0;
            border-left: 0;
            .box {
                .name {
                    .b {
                        color: #fff;
                    }
                    .s {
                        color: #fff;
                    }
                }
            }
        }
        .box-bk5 {
            width: 50%;
            border-top: 0;
            border-left: 0;
            transition: all .3s;
            overflow: hidden;
            background-size: 115% !important;
            @media screen and (max-width: 1100px) {
                width: 66.7%;
            }
            @media screen and (max-width: 750px) {
                width: 100%;
            }
            .box {
                position: relative;
                padding: 60px 36px;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 750px) {
                    padding: 0;
                    padding-bottom: (385 / 699) * 100%;
                }
                .name {
                    width: 130px;
                    margin-top: 0;
                    @media screen and (max-width: 750px) {
                        position: absolute;
                        top: px2rem(38px);
                        right: px2rem(18px);
                    }
                    @media screen and (max-width: 500px) {
                        top: 8px;
                        right: 8px;
                        width: 80px;
                    }
                    .b {
                        text-align: inherit;
                        color: #fff;
                    }
                    .s {
                        text-align: inherit;
                        color: #fff;
                    }
                }
            }
        }
        .box-bk5:hover {
            background-size: 130% !important;
        }
    }
}
.index-solution {
    background-color: #f5f5f5;
    padding: 78px 0 90px;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .solution-content {
        margin-top: 18px;
        @media screen and (max-width: 1000px) {
            margin-top: 20px;
        }
        .solution-swiper {
            .swiper-slide {
                position: relative;
                padding: px2rem(30px) 0;
                box-sizing: border-box;
                @media screen and (max-width: 1000px) {
                    padding: px2rem(42px) 0 px2rem(60px);
                }
                .box {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 42px 30px 45px;
                    box-sizing: border-box;
                    background-color: #fff;
                    border: 1px solid #eee;
                    z-index: 2;
                    transform: scale(1);
                    transition: transform .5s;
                    @media screen and (max-width: 1000px) {
                        padding: px2rem(30px);
                    }
                    .circle {
                        position: relative;
                        z-index: 2;
                        width: 75px;
                        height: 75px;
                        border-radius: 50%;
                        background-color: #f5f5f5;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (max-width: 1000px) {
                            width: 60px;
                            height: 60px;
                        }
                        .iconfont {
                            font-size: 32px;
                            @media screen and (max-width: 1000px) {
                                font-size: 22px;
                            }
                        }
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .info {
                        margin-top: px2rem(14px);
                        text-align: center;
                        .b {
                            position: relative;
                            z-index: 2;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 30px;
                            color: #555;
                            text-align: center;
                        }
                        .s {
                            font-size: 13px;
                            line-height: 22px;
                            color: #888;
                            text-align: center;
                        }
                        .s2 {
                            @media screen and (max-width: 1000px) {
                                display: none;
                            }
                        }
                    }
                }
                .hover-img {
                    display: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .swiper-slide:hover {
                z-index: 3;
                .box {
                    transform: scale(1.1);
                    //box-shadow: 0 0 15px 2px rgba(0, 0, 0, .1);
                    border: 0;
                    .circle {
                        background-color: rgba(255, 255, 255, .6);
                        animation-name: b2t;
                        -webkit-animation-name: b2t;
                        -ms-animation-name: b2t;
                        animation-timing-function: linear;
                        -webkit-animation-timing-function: linear;
                        -ms-animation-timing-function: linear;
                        animation-fill-mode: forwards;
                        -webkit-animation-fill-mode: forwards;
                        -ms-animation-fill-mode: forwards;
                        animation-duration: .8s;
                        -webkit-animation-duration: .8s;
                        -ms-animation-duration: .8s;
                    }
                    .info {
                        .b {
                            color: #fff;
                            animation-name: b2t;
                            -webkit-animation-name: b2t;
                            -ms-animation-name: b2t;
                            animation-timing-function: linear;
                            -webkit-animation-timing-function: linear;
                            -ms-animation-timing-function: linear;
                            animation-fill-mode: forwards;
                            -webkit-animation-fill-mode: forwards;
                            -ms-animation-fill-mode: forwards;
                            animation-duration: .8s;
                            -webkit-animation-duration: .8s;
                            -ms-animation-duration: .8s;
                        }
                    }
                }
                .hover-img {
                    display: block;
                    animation-name: case-fadein;
                    -webkit-animation-name: case-fadein;
                    -ms-animation-name: case-fadein;
                    animation-timing-function: linear;
                    -webkit-animation-timing-function: linear;
                    -ms-animation-timing-function: linear;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    -ms-animation-fill-mode: forwards;
                    animation-duration: .8s;
                    -webkit-animation-duration: .8s;
                    -ms-animation-duration: .8s;
                }
            }
            .swiper-pagination {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    margin: 0 5px;
                    outline: none;
                    background-color: #c4c4c4;
                    opacity: 1;
                    @media screen and (max-width: 500px) {
                        width: 8px;
                        height: 8px;
                        margin: 0 4px;
                    }
                }
                .swiper-pagination-bullet-active {
                    opacity: 1;
                    background-color: $baseColor;
                }
            }
        }
    }
}
.index-gg {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding-bottom: (395 / 1920) * 100%;
    @media screen and (max-width: 1000px) {
        display: none;
    }
}
.index-gg-mobile {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding-bottom: (265 / 750) * 100%;
    display: none;
    @media screen and (max-width: 1000px) {
        display: block;
    }
}
.index-news {
    padding: 84px 0 110px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .news-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: px2rem(42px);
        @media screen and (max-width: 1000px) {
            margin-top: 20px;
        }
        .content-left {
            padding: 28px;
            box-sizing: border-box;
            background-color: #fff;
            @media screen and (max-width: 1000px) {
                width: 100%;
                padding: px2rem(30px);
            }
            .news-recommend {
                position: relative;
                width: 613px;
                @media screen and (max-width: 1400px) {
                    width: 450px;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: (393 / 613) * 100%;
                    overflow: hidden;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 40%;
                        background: linear-gradient(0deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .5) 30%, transparent);
                        z-index: 2;
                    }
                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                }
                .title {
                    position: absolute;
                    left: px2rem(20px);
                    bottom: 25px;
                    line-height: 18px;
                    height: 18px;
                    font-size: 18px;
                    color: #fff;
                    font-weight: bold;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    z-index: 4;
                    width: calc(100% - 100px);
                    @media screen and (max-width: 1400px) {
                        height: 16px;
                        font-size: 16px;
                        bottom: 15px;
                    }
                    @media screen and (max-width: 500px) {
                        bottom: 15px;
                        width: calc(100% - 60px);
                    }
                }
                .news-pagination {
                    display: flex;
                    width: auto;
                    right: 30px;
                    left: auto;
                    bottom: 30px;
                    @media screen and (max-width: 1400px) {
                        right: 20px;
                        bottom: 20px;
                    }
                    @media screen and (max-width: 500px) {
                        right: 10px;
                        bottom: 15px;
                    }
                    .swiper-pagination-bullet {
                        background-color: $baseColor;
                        opacity: 1;
                        width: 10px;
                        height: 10px;
                        @media screen and (max-width: 1600px) {
                            width: 8px;
                            height: 8px;
                        }
                    }
                    .swiper-pagination-bullet-active {
                        background-color: #fff;
                    }
                }
            }
        }
        .content-right {
            flex: 1;
            padding-left: 12px;
            box-sizing: border-box;
            @media screen and (max-width: 1000px) {
                width: 100%;
                padding-left: 0;
                margin-top: px2rem(20px);
            }
            .news-list {
                flex: 1;
                background-color: #fff;
                height: 100%;
                display: flex;
                align-items: center;
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
                .list-ul {
                    width: 100%;
                    padding: 0 40px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1000px) {
                        padding: 0 px2rem(20px);
                    }
                    .list-li {
                        width: 100%;
                        border-bottom: 1px dashed #ebebeb;
                        box-sizing: border-box;
                        padding: 36px 0 39px;
                        @media screen and (max-width: 1400px) {
                            padding: 30px 0 25px;
                        }
                        @media screen and (max-width: 1320px) {
                            padding: 20px 0 15px;
                        }
                        @media screen and (max-width: 1000px) {
                            padding: px2rem(45px) 0 px2rem(33px);
                        }
                        a {
                            display: flex;
                            align-items: flex-end;
                            .data {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                .day {
                                    font-size: 36px;
                                    line-height: 30px;
                                    font-family: Arial;
                                    color: $baseColor;
                                    @media screen and (max-width: 1000px) {
                                        font-size: px2rem(44px);
                                        line-height: px2rem(40px);
                                    }
                                }
                                .time {
                                    font-family: Arial;
                                    font-size: 14px;
                                    line-height: 1;
                                    margin-top: 2px;
                                    color: #333;
                                    @media screen and (max-width: 1000px) {
                                        font-size: px2rem(22px);
                                    }
                                }
                            }
                            .info {
                                flex: 1;
                                padding-left: px2rem(20px);
                                box-sizing: border-box;
                                .info-title {
                                    font-size: 16px;
                                    line-height: 16px;
                                    color: #404040;
                                    overflow: hidden;
                                    height: 16px;
                                    @media screen and (max-width: 1000px) {
                                        font-size: px2rem(28px);
                                        line-height: px2rem(28px);
                                        height: px2rem(28px);
                                    }
                                }
                                .info-dsc {
                                    font-size: 13px;
                                    line-height: 13px;
                                    height: 13px;
                                    overflow: hidden;
                                    margin-top: 12px;
                                    color: #777;
                                    @include multiEllipsis(1);
                                    @media screen and (max-width: 1000px) {
                                        font-size: px2rem(24px);
                                        line-height: px2rem(24px);
                                        height: px2rem(24px);
                                    }
                                }
                            }
                        }
                    }
                    .list-li:hover {
                        a {
                            .info {
                                .info-title {
                                    color: $onColor;
                                }
                            }
                        }
                    }
                    .list-li:nth-last-of-type(1) {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
.index-video {
    padding: 75px 0 108px;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .video-content {
        margin-top: 30px;
        cursor: pointer;
        @media screen and (max-width: 1000px) {
            margin-top: 20px;
        }
        .img {
            display: block;
            width: 100%;
            overflow: hidden;
            @media screen and (max-width: 1000px) {
                display: none;
            }
            img {
                max-width: 100%;
                height: auto;
                transition: all .3s;
            }
        }
        .img:hover {
            img {
                transform: scale(1.1);
            }
        }
        .img-mobile {
            display: none;
            @media screen and (max-width: 1000px) {
                display: block;
            }
        }
    }
}
.index-title {
    display: flex;
    justify-content: center;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            display: block;
        }
        .word {
            font-size: 26px;
            line-height: 28px;
            font-weight: bold;
            color: #333;
            text-transform: uppercase;
            @media screen and (max-width: 1000px) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .word2 {
            font-family: Arial;
        }
        .color {
            color: $baseColor;
        }
        .bit {
            width: 5px;
            height: 5px;
            background-color: #333;
            border-radius: 50%;
            margin: 0 3px;
            @media screen and (max-width: 1000px) {
                width: 3px;
                height: 3px;
                margin: 0 2px;
            }
        }
    }
}
@keyframes case-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes case-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes case-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes case-fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes case-fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-ms-keyframes case-fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes b2t {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}
@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}
