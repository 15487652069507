.join-container {
    background-color: #f5f5f5;
    padding: px2rem(50px) 0 px2rem(74px);
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
}
.join-content {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @media screen and (max-width: 750px) {
        display: block;
    }
    .left {
        width: (666 / 1360) * 100%;
        box-sizing: border-box;
        //display: flex;
        //justify-content: center;
        //flex-direction: column;
        padding-left: 118px;
        @media screen and (max-width: 1500px) {
            padding-left: 22px;
        }
        @media screen and (max-width: 750px) {
            padding: px2rem(22px);
            width: 100%;
        }
        .name {
            font-size: 24px;
            line-height: 24px;
            font-weight: bold;
            color: #333;
            margin-bottom: px2rem(25px);
            @media screen and (max-width: 500px) {
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 16px;
            }
        }
        .same {
            display: flex;
            margin-bottom: 10px;
            .iconfont {
                display: block;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                text-align: center;
                line-height: 25px;
                color: #fff;
                font-size: 12px;
                background-color: $baseColor;
                @media screen and (max-width: 500px) {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                }
            }
            .word {
                display: block;
                margin-left: px2rem(10px);
                font-size: 14px;
                line-height: 25px;
                color: #666;
                @media screen and (max-width: 500px) {
                    line-height: 20px;
                    font-size: 13px;
                }
            }
            .info {
                flex: 1;
                display: block;
                font-size: 14px;
                line-height: 25px;
                color: #666;
                @media screen and (max-width: 500px) {
                    line-height: 20px;
                    font-size: 13px;
                }
            }
        }
        .same:nth-last-of-type(1) {
            margin-bottom: 0px;
        }
    }
    .right {
        flex: 1;
        padding: px2rem(27px) px2rem(30px);
        box-sizing: border-box;
        height: px2rem(326px);
        .map {
            width: 100%;
            height: 100%;
        }
    }
}
.join-content:nth-last-of-type(1) {
    margin-bottom: 0;
}
.online-container {
    width: 100%;
    background-color: #fff;
    .online-content {
        padding: 60px 140px 75px;
        box-sizing: border-box;
        @media screen and (max-width: 1500px) {
            padding: px2rem(50px) px2rem(22px) px2rem(60px);
        }
        @media screen and (max-width: 500px) {
            padding: px2rem(50px) 0px;
        }
        .name {
            font-size: 36px;
            line-height: 36px;
            color: #333;
            text-align: center;
            @media screen and (max-width: 500px) {
                font-size: 20px;
                line-height: 20px;
            }
        }
        .en {
            font-size: 18px;
            line-height: 18px;
            font-family: Arial;
            color: #888;
            margin-top: px2rem(8px);
            text-align: center;
            text-transform: uppercase;
            @media screen and (max-width: 500px) {
                font-size: 14px;
                line-height: 14px;
            }
        }
        .form {
            max-width: 1080px;
            width: 100%;
            margin: px2rem(26px) auto 0;
            .form-content {
                overflow: hidden;
                input,
                textarea,
                select {
                    display: block;
                    border: none;
                    outline: none;
                    background: none;
                    color: #333;
                    width: 100%;
                    font-size: 14px;
                }
                .input-group50 {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 px2rem(-10px);
                    li {
                        margin-bottom: 15px;
                        padding: 0 px2rem(10px);
                        width: 50%;
                        box-sizing: border-box;
                    }
                }
                .input-group100 {
                    display: flex;
                    li {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    .input-box {
                        height: auto;
                    }
                }
                .input-box,
                .select-box {
                    display: flex;
                    height: 50px;
                    line-height: 50px;
                    background: #f5f5f5;
                    border: 1px solid #e2e8ef;
                    border-radius: 5px;
                }
                .input-box {
                    padding: 0 px2rem(20px);
                }
                .input {
                    flex: 1;
                    display: flex;
                    align-items: center;
                }
                .textarea-box {
                    margin-bottom: 20px;
                    padding: 12px 20px;
                    background: #f5f5f5;
                    line-height: 24px;
                    border: 1px solid #e2e8ef;
                    border-radius: 5px;
                    textarea {
                        resize: none;
                        line-height: 24px;
                        overflow: auto;
                    }
                }
                .input-text {
                    color: #333;
                    font-size: 14px;
                    em {
                        font-style: normal;
                        color: #d21e1e;
                        font-size: 12px;
                    }
                }
                button {
                    width: 220px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    background: $baseColor;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    transition: all .3s;
                    &:hover {
                        opacity: .85;
                    }
                }
                .submit-box {
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 800px) {
                        display: block;
                    }
                    .yz {
                        width: calc(50% - 10px);
                        border-radius: 5px;
                        overflow: hidden;
                        border: 1px solid #e2e8ef;
                        @media screen and (max-width: 800px) {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                    .btn {
                        width: calc(50% - 10px);
                        display: flex;
                        justify-content: flex-end;
                        @media screen and (max-width: 800px) {
                            width: 100%;
                            justify-content: center;
                        }
                    }
                }
                @media screen and (max-width: 800px) {
                    font-size: 13px;
                    .input-group50 {
                        li {
                            margin-bottom: 15px;
                            width: 100%;
                        }
                    }
                    .input-box,
                    .select-box {
                        height: 40px;
                        line-height: 40px;
                    }
                    .select {
                        select {
                            line-height: 40px;
                        }
                    }
                    .textarea-box {
                        margin-bottom: 15px;
                        padding: 8px 15px;
                    }
                    .qrcode-box {
                        .input-box {
                            flex: 1;
                            width: auto;
                        }
                    }
                    .qrcode-img {
                        flex: 0 0 90px;
                        img {
                            max-width: 100%;
                            max-height: 40px;
                        }
                    }
                    button {
                        float: none;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}