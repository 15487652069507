.header {
    //栏目头部
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: rgba(255, 255, 255, .95);
    z-index: 999;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    .header-web-tree {
        width: 100%;
        margin: 0 auto !important;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
        //padding: 0 160px;
        box-sizing: border-box;
        // display: none;
        .header-logo {
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .header-right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            height: 100%;
        }
        .header-nav {
            height: 100%;
            margin-left: 50px;
            @media screen and (max-width: 1380px) {
                margin-left: 20px;
            }
            @media screen and (max-width: 1260px) {
                margin-left: 10px;
            }
            .header-ul {
                display: flex;
                height: 100%;
                .header-li:nth-of-type(1) {
                    margin-left: 0px !important;
                }
                .header-li {
                    position: relative;
                    text-align: center;
                    height: 100%;
                    box-sizing: border-box;
                    .li1 {
                        position: relative;
                        display: inline-block;
                        height: 100%;
                        font-size: 15px;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 100px;
                        color: #222;
                        padding: 0 26px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1380px) {
                            padding: 0 10px;
                        }
                    }
                    .li1:before {
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 8px;
                        background-color: #ccc;
                    }
                    .hover-show {
                        position: fixed;
                        display: none;
                        top: 100px;
                        left: 0;
                        width: 100%;
                        background-color: rgba(0, 0, 0, .3);
                        z-index: 999;
                        box-sizing: border-box;
                        padding: 20px 0;
                        justify-content: center;
                        ul {
                            display: flex;
                            justify-content: center;
                            li {
                                position: relative;
                                font-size: 14px;
                                line-height: 38px;
                                text-align: center;
                                padding: 0 50px;
                                box-sizing: border-box;
                                a {
                                    width: 100%;
                                    display: block;
                                    color: #666;
                                    p {
                                        font-size: 12px;
                                        line-height: 30px;
                                        color: #fff;
                                        text-align: center;
                                        img {
                                            max-width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                            li:before {
                                position: absolute;
                                content: '';
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 1px;
                                height: 7px;
                                background-color: rgba(255, 255, 255, .3);
                            }
                            li:hover {
                                a {
                                    p {
                                        color: $baseColor;
                                    }
                                }
                            }
                        }
                    }
                }
                .header-li:hover {
                    .li1 {
                        color: $baseColor;
                        line-height: 100px;
                    }
                    .hover-show {
                        display: flex;
                    }
                }
                .on {
                    .li1 {
                        color: $baseColor;
                        line-height: 100px;
                    }
                }
                .active {
                    .li1 {
                        color: $baseColor;
                        line-height: 100px;
                    }
                }
            }
        }
        .language {
            display: flex;
            align-items: center;
            margin: 0 35px 0 25px;
            span {
                display: block;
            }
            .icon {
                font-size: 14px;
                color: #222;
            }
            .word {
                color: #222;
                font-size: 15px;
                margin-left: 8px;
            }
        }
        //二级菜单
        .submenu {
            position: absolute;
            top: 100px;
            width: 100%;
            left: 0;
            .show {
                opacity: .98;
                visibility: visible;
            }
            &-item {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                left: bfb(440, 1920);
                display: flex;
                padding: 42px 52px 46px 68px;
                width: bfb(675, 1920);
                background-color: #f9f9f9;
                box-shadow: 0 18px 25px -18px rgba(0, 0, 0, .1) inset;
                box-sizing: border-box;
                &-left {
                    width: bfb(250, 555);
                    padding-right: 10px;
                    > ul {
                        font-size: 14px;
                        line-height: 42px;
                        box-sizing: border-box;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 -5px;
                        overflow: hidden;
                        > li {
                            width: 100%;
                            box-sizing: border-box;
                            padding: 0 5px;
                            > a {
                                width: 100%;
                                color: #666;
                                display: inline-block;
                            }
                        }
                        li:hover {
                            > a {
                                color: $onColor;
                            }
                        }
                    }
                }
                &-right {
                    width: bfb(305, 555);
                    padding-left: px2rem(65px);
                    box-sizing: border-box;
                    border-left: 1px solid #e6e5e3;
                    .img-box {
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                        padding-bottom: (151 / 233) * 100%;
                        .img {
                            @include baseImg();
                        }
                    }
                }
            }
            &-item:nth-of-type(2) {
                //flex-direction: row-reverse;
                left: bfb(740, 1920);
            }
            &-item:nth-of-type(3) {
                left: bfb(790, 1920);
            }
            &-item:nth-of-type(4) {
                left: bfb(830, 1920);
            }
            &-item:nth-of-type(5) {
                left: bfb(850, 1920);
            }
        }
        .header-search {
            position: relative;
            display: flex;
            align-items: center;
            .input {
                input {
                    display: block;
                    height: 37px;
                    line-height: 35px;
                    box-sizing: border-box;
                    border: 1px solid #d0d0d0;
                    font-size: 13px;
                    width: 205px;
                    background-color: #f6f6f6;
                    padding-left: 10px;
                    color: #222;
                }
                input[type=text] {
                    color: #222;
                }
                input[type=text]::placeholder {
                    color: #222;
                }
            }
            .search-btn {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                line-height: 35px;
                font-size: 17px;
                color: #999;
                cursor: pointer;
                border: none;
                background-color: #f5f5f5;
                margin-right: 10px;
            }
            .search-btn:after {
                position: absolute;
                z-index: 1;
                content: '';
                width: 1px;
                height: 10px;
                background-color: #c5c5c5;
                top: 50%;
                transform: translateY(-50%);
                left: -10px;
            }
        }
        .header-en {
            display: block;
            font-size: 15px;
            line-height: 100px;
            color: #333;
            margin-left: 26px;
            @media screen and (max-width: 1380px) {
                margin-left: 10px;
            }
        }
    }
    @keyframes r2l {
        0% {
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @-webkit-keyframes r2l {
        0% {
            -webkit-transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            opacity: 1;
        }
    }
    @-ms-keyframes r2l {
        0% {
            -ms-transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -ms-transform: translateX(0);
            opacity: 1;
        }
    }
    //搜索框
    .header-search-form {
        display: none;
        position: absolute;
        right: 33px;
        //top: 0px;
        width: 240px;
        background-color: rgba(255, 255, 255, .9);
        box-shadow: 0 1px 4px rgba(0, 0, 0, .1) inset;
        border-radius: 40px;
        transition: all .3s;
        z-index: 6;
        overflow: hidden;
        form {
            display: flex;
        }
        .input {
            flex: 1;
            min-width: 0;
        }
        input,
        button {
            display: block;
            height: 40px;
            line-height: 40px;
            outline: none;
            background: none;
            border: none;
        }
        input {
            padding-left: 20px;
            width: 100%;
            color: #555;
            box-sizing: border-box;
        }
        button {
            padding: 0 16px;
            font-size: 18px;
            color: #333;
            cursor: pointer;
            transition: all .3s;
            button:hover {
                color: #e6212a;
            }
        }
        ::-webkit-input-placeholder {
            color: #666;
        }
        ::-moz-placeholder {
            color: #666;
        }
        :-ms-input-placeholder {
            color: #666;
        }
    }
    //手机版头部
    .header-mobile {
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        padding: 0 px2rem(30px);
        box-sizing: border-box;
        z-index: 100;
        @media screen and (max-width: 500px) {
            height: 60px;
        }
        .header-mobile-logo {
            img {
                display: block;
                width: px2rem(273px);
                height: auto;
                @media screen and (max-width: 500px) {
                    width: 200px;
                }
            }
        }
        .header-mobile-right {
            .language {
                display: flex;
                align-items: center;
                span {
                    display: block;
                }
                .icon {
                    font-size: 26px;
                    color: #222;
                    @media screen and (max-width: 500px) {
                        font-size: 18px;
                    }
                }
                .word {
                    color: #222;
                    font-size: 26px;
                    margin-left: 6px;
                    line-height: 1;
                    @media screen and (max-width: 500px) {
                        font-size: 18px;
                    }
                }
            }
            .btn {
                cursor: pointer;
                color: #333;
                font-size: 30px;
                @media screen and (max-width: 500px) {
                    font-size: 20px;
                }
            }
            .icon-sousuo3 {
                margin-left: px2rem(42px);
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .header-web {
            display: none;
        }
        .header-web-tree {
            display: none;
        }
        .header-mobile {
            display: flex;
        }
        .header-mobile-img {
            display: block;
        }
        .header-mobile-right {
            display: flex;
            justify-content: left;
            align-items: center;
            .header-search-position {
                width: px2rem(200px);
                min-width: 140px;
                top: 100px;
                right: px2rem(70px);
                @media screen and (max-width: 500px) {
                    top: 60px;
                }
                form {
                    .icon-sousuo {
                        margin-right: 0px !important;
                    }
                }
            }
            .icon-daohangmoren {
                display: inline-block;
            }
        }
    }
}
.nav-mobile {
    position: absolute;
    width: 100%;
    top: px2rem(190px);
    right: 0;
    bottom: 0;
    z-index: 103;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    @media screen and (max-width: 500px) {
        top: 120px;
    }
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 8889;
        transition: transform .4s ease;
        //@media screen and (max-width: 750px) {
        //    top: 100px;
        //}
        &.anim {
            .nav-mobile-search {
                transform: translateX(0) !important;
            }
        }
    }
    &-back {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 px2rem(50px);
        width: 100%;
        height: px2rem(100px);
        background-color: $baseColor;
        color: #fff;
        box-sizing: border-box;
        @media screen and (max-width: 500px) {
            height: 60px;
        }
        span {
            position: relative;
            z-index: 1;
            font-size: 28px;
            line-height: px2rem(100px);
            color: #fff;
            @media screen and (max-width: 500px) {
                font-size: 20px;
                line-height: 60px;
            }
        }
        .fh {
            margin-left: px2rem(20px);
            @media screen and (max-width: 500px) {
                margin-left: 5px;
            }
        }
    }
    &-search {
        position: absolute;
        right: 0;
        top: px2rem(100px);
        background-color: #f0f0f2;
        height: px2rem(90px);
        display: flex;
        z-index: 104;
        box-sizing: border-box;
        align-items: center;
        padding: 0 px2rem(30px) 0 px2rem(65px);
        width: 100%;
        transform: translateX(100%);
        transition: transform .4s ease;
        @media screen and (max-width: 500px) {
            height: 60px;
            top: 60px;
        }
        .box {

            display: flex;
            width: 100%;
            box-sizing: border-box;
        }
        .input {
            position: relative;
            flex: 1;
            overflow: hidden;
        }
        input[type="text"] {
            width: 100%;
            height: px2rem(90px);
            line-height: px2rem(90px);
            appearance: none;
            border: none;
            font-size: px2rem(26px);
            background: none;
            color: #000;
        }
        button {
            border: none;
            appearance: none;
            //font-family: iconfont;
            display: block;
            background: none;
            font-size: px2rem(40px);
            color: #333;
            //&:before {
            //    content: '\e627';
            //}
        }
    }
    &-wp {
        background-color: #fff;
        position: relative;
        & > li {
            border-bottom: 1px solid #e5e5e5;
        }
    }
    &-title {
        position: relative;
        > a {
            position: relative;
            display: block;
            padding: 0 px2rem(30px) 0 px2rem(65px);
            width: 100%;
            line-height: px2rem(100px);
            font-size: px2rem(32px);
            color: #666;
            z-index: 2;
            backface-visibility: hidden;
            box-sizing: border-box;
        }
        &.open {
            .nav-mobile-child-btn i {
                &:before {
                    content: '\e8aa';
                }
            }
        }
    }
    &-child-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 px2rem(30px) 0 px2rem(65px);
        text-align: right;
        line-height: px2rem(100px);
        box-sizing: border-box;
        z-index: 1;
        i {
            font-size: px2rem(22px);
            color: #666;
            font-family: "iconfont";
            transition: all .3s;
            &:before {
                content: "\e8a9";
            }
        }
        + a {
            display: inline-block;
            width: calc(100% - 50px);
        }
    }
    .sub {
        display: none;
        border-top: 1px solid #e2e2e2;
        li {
            position: relative;
            overflow: hidden;
            &:not(:last-child) a {
                border-bottom: 1px solid #e2e2e2;
            }
            a {
                display: block;
                padding: 0 px2rem(30px) 0 px2rem(65px);
                line-height: px2rem(100px);
                height: px2rem(100px);
                font-size: px2rem(28px);
                color: #666;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}
