.honor-container {
    background-color: #f5f5f5;
    padding: px2rem(38px) 0 px2rem(76px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }
    .wp1360 {
        overflow: hidden;
    }
    .produce-class {
        margin-bottom: px2rem(46px);
    }
    .honor-content {
        display: flex;
        flex-wrap: wrap;
        margin: -18px;
        .content-box {
            width: 25%;
            padding: 18px;
            box-sizing: border-box;
            @media screen and (max-width: 1360px) {
                padding: 8px;
            }
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .box {
                background-color: #fff;
                padding-bottom: px2rem(48px);
                box-sizing: border-box;
                cursor: pointer;
                @media screen and (max-width: 1360px) {
                    padding-bottom: px2rem(36px);
                }
                .img-box {
                    width: 100%;
                    padding: 30px 10px 10px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1360px) {
                        padding: 20px 10px 10px;
                    }
                    .img {
                        width: 100%;
                        position: relative;
                        padding-bottom: 69%;
                        overflow: hidden;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                            transition: all .3s;
                        }
                    }
                }
                .name {
                    margin-top: 10px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    font-size: 16px;
                    line-height: 16px;
                    height: 16px;
                    color: #333;
                    @include multiEllipsis(1);
                    text-align: center;
                    @media screen and (max-width: 1360px) {
                        font-size: 14px;
                        line-height: 14px;
                        height: 14px;
                    }
                }
            }
            .box:hover {
                .img-box {
                    .img {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
            }
        }
    }
    .paged {
        margin-top: px2rem(44px);
    }
}
