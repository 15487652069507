.download-container {
    background-color: #f5f5f5;
    padding: px2rem(38px) 0 px2rem(76px);
    @media screen and (max-width: 1000px) {
        padding: px2rem(80px) 0;
    }

    .paged {
        margin-top: px2rem(50px);
    }
}

.zlxz-box {
    width: 100%;
    padding: 0 42px;
    box-sizing: border-box;
    @media screen and (max-width: 1360px) {
        padding: 0;
    }
    ul {
        width: 100%;
        li:nth-of-type(2n) {
            background-color: #fff;
        }
        li:nth-of-type(2n + 1) {
            background-color: #f5f5f5;
        }
        li {
            width: 100%;
            a {
                display: flex;
                //justify-content: space-between;
                padding: 15px 16px 15px 10px;
                box-sizing: border-box;
                border-bottom: 1px dashed #e2e2e2;
                width: 100%;
                @media screen and (max-width: 750px) {
                    padding: 12px;
                }
            }
            .li-left {
                display: flex;
                align-items: center;
                width: calc(100% - 40px);
                .circle {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    background-color: $baseColor;
                    text-align: center;
                    line-height: 20px;
                    i {
                        font-size: 12px;
                        color: #fff;
                    }
                }
                .title {
                    display: -webkit-box;
                    height: 21px;
                    flex: 1;
                    text-indent: 6px;
                    font-size: 15px;
                    line-height: 21px;
                    @include multiEllipsis(1);
                    color: #777;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    max-width: 800px;
                    width: 100%;
                }
            }
            .li-right {
                flex: 1;
                white-space: nowrap;
                display: flex;
                justify-content: flex-end;
                i {
                    color: $baseColor;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
        li:hover {
            a {
                box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
                //transform: translateY(-1px);
            }
            .li-left {
                .title {
                    color: $baseColor;
                }
            }
            .li-right {
                i {
                    color: $baseColor;
                }
            }
        }
    }
}